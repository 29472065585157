/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

const navigation = {
  solutions: [
    { name: 'Our Schools', href: '/our-schools' },
    { name: 'Tournaments', href: '/events/tournaments' },
    { name: 'Birthday Parties', href: '/events/birthday-parties' },
    { name: 'Summer Camps', href: '/events/summer-camps' },
  ],

  company: [
    { name: 'Our System', href: '/about-us#our-system' },
    { name: 'Our History', href: '/about-us#our-history' },
    { name: 'Our Future', href: '/about-us#our-future' },
  ],
  social: [
    {
      name: 'Facebook',
      href: 'https://www.facebook.com/yuanyendo',
      icon: (props) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path
            fillRule="evenodd"
            d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
            clipRule="evenodd"
          />
        </svg>
      ),
    },
    /* {
      name: 'Instagram',
      href: '#',
      icon: (props) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path
            fillRule="evenodd"
            d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
            clipRule="evenodd"
          />
        </svg>
      ),
    },
    {
      name: 'Twitter',
      href: '#',
      icon: (props) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
        </svg>
      ),
    }, */

  ],
};

export default function Footer() {
  const data = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "logo-inverse.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FIXED, width: 180, )
        }
      }
      belts: allSanityBelt(sort: {order: ASC, fields: classes___belt___order}) {
        nodes {
          id
          name
          slug {
            current
          }
        }
      }
    }
  `);
  return (
    <footer className="bg-primary" aria-labelledby="footer-heading">
      <h2 id="footer-heading" className="sr-only">
        Footer
      </h2>
      <div className="px-4 py-12 mx-auto lg:py-16 lg:px-16 ">
        <div className="grid grid-cols-1 gap-8 text-center lg:text-left sm:grid-cols-3 lg:grid-cols-5 justify-items-center ">
          {/* col 1 */}
          <div className="grid col-span-1 sm:col-span-3 lg:col-span-2 justify-items-center lg:justify-items-start">
            <Link to="/">
              <GatsbyImage image={data.logo.childImageSharp.gatsbyImageData} alt="Yuan Yen Do Logo Inverse" className="flex-shrink-0 object-contain w-full h-full mb-4 lg:ml-8" />
            </Link>
            <div className="grid grid-cols-1 space-y-4 justify-items-center lg:justify-items-start">
              <p className="text-base text-center text-gray-50 lg:text-left">
                Yuan Yen Do is a self defense based martial arts system which centers around
                developing the character of the members through a fun and structured teaching
                system.
              </p>
              <div className="flex space-x-6">
                {navigation.social.map((item) => (
                  <a key={item.name} href={item.href} className="flex items-center text-sm text-gray-50 hover:text-gray-300" target="_blank" rel="noreferrer">
                    <span className="sr-only">{item.name}</span>
                    <item.icon className="w-6 h-6 pr-1" aria-hidden="true" />
                    Follow us on Facebook
                  </a>
                ))}
              </div>
            </div>
          </div>
          {/* col 2 */}
          <div className="">
            <h3 className="text-sm font-semibold tracking-wider uppercase text-gray-50">Quick Links</h3>
            <ul className="mt-4 space-y-4 lg:ml-4">
              {navigation.solutions.map((item) => (
                <li key={item.name}>
                  <Link to={item.href} className="text-base text-gray-300 hover:text-gray-500">
                    {item.name}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
          <div className="">
            <h3 className="text-sm font-semibold tracking-wider uppercase text-gray-50">Class Schedules</h3>
            <ul className="mt-4 space-y-4 lg:ml-4">
              {data.belts.nodes.map((item) => (
                <li
                  key={`footer-nav-${item.name}`}
                >
                  <Link
                    to={`/class/${item.slug.current}s`}
                    className="text-base text-gray-300 hover:text-gray-500"
                  >
                    {item.name}
                    s
                  </Link>
                </li>
              ))}
            </ul>

          </div>
          <div className="">
            <h3 className="text-sm font-semibold tracking-wider uppercase text-gray-50">Yuan Yen Do</h3>
            <ul className="mt-4 space-y-4 lg:ml-4">
              {navigation.company.map((item) => (
                <li key={item.name}>
                  <Link to={item.href} className="text-base text-gray-300 hover:text-gray-500">
                    {item.name}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      <div className="py-8 border-t border-gray-200">
        <p className="text-xs text-center sm:text-sm text-gray-50">
          &copy; 2022 Yuan Yen Do Self Defense, Inc. All rights reserved. View our
          {' '}
          <Link to="/privacy-policy" className="underline hover:opacity-75">Privacy Policy</Link>
        </p>
      </div>
    </footer>
  );
}
