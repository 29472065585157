import React, { Fragment } from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import { Popover, Transition } from '@headlessui/react';
import {
  MenuIcon,
  XIcon,
} from '@heroicons/react/outline';
import { ChevronDownIcon } from '@heroicons/react/solid';
import { GatsbyImage } from 'gatsby-plugin-image';

const specialEvents = [
  {
    name: 'Tournaments',
    href: '/events/tournaments',
  },
  {
    name: 'Birthday Parties',
    href: '/events/birthday-parties',
  },
  {
    name: 'Summer Camps',
    href: '/events/summer-camps',
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function Header() {
  const data = useStaticQuery(graphql`
  query {
    logo: file(relativePath: { eq: "logo.png" }) {
      childImageSharp {
          gatsbyImageData(layout: FIXED, width: 112, )
        }
      }
      belts: allSanityBelt(sort: {order: ASC, fields: order}) {
        nodes {
          id
          name
          slug {
            current
          }
        }
      }
      schools: allSanitySchool {
        nodes {
          name
          address {
            city
            state
            street
            zip
          }
          phone
          _id
          slug {
            current
          }
        }
      }
    }
  `);

  return (
    <div className="fixed top-0 z-30 w-full border-b-2 bg-background-light filter drop-shadow-lg border-primary">
      <Popover className="lg:container lg:mx-auto">
        <div className="flex items-center justify-between px-5 pt-4 pb-2 lg:px-10 lg:justify-start md:pr-10">
          <div className="flex justify-start lg:w-0 lg:flex-1">
            <Link to="/">
              <span className="sr-only">Yuan Yen Do Self Defense</span>
              <GatsbyImage image={data.logo.childImageSharp.gatsbyImageData} alt="Yuan Yen Do Self Defense Logo" />
            </Link>
          </div>

          <div className="-my-2 -mr-2 md:hidden">
            <Popover.Button className="inline-flex justify-center p-2 text-gray-400 rounded-md lg:items-center hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-gray-200">
              <span className="sr-only">Open menu</span>
              <MenuIcon className="w-6 h-6" aria-hidden="true" />
            </Popover.Button>
          </div>
          <Link to="/" className="hidden px-8 text-sm font-medium text-gray-500 md:flex lg:text-base hover:text-gray-900">
            Home
          </Link>
          <Popover.Group as="nav" className="hidden px-5 md:items-center md:flex">
            <Popover className="relative">
              {({ open }) => (
                <>
                  <Popover.Button
                    className={classNames(
                      open ? 'text-gray-900' : 'text-gray-500',
                      'group rounded-md inline-flex items-center text-sm lg:text-base font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-4 focus:ring-gray-200',
                    )}
                  >
                    <p className="lg:flex">
                      <span className="hidden lg:flex lg:pr-1">Class</span>
                      Schedule
                    </p>
                    <ChevronDownIcon
                      className={classNames(
                        open ? 'text-gray-600' : 'text-gray-400',
                        'ml-1 lg:ml-2 h-5 w-5 group-hover:text-gray-500',
                      )}
                      aria-hidden="true"
                    />
                  </Popover.Button>

                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-200"
                    enterFrom="opacity-0 translate-y-1"
                    enterTo="opacity-100 translate-y-0"
                    leave="transition ease-in duration-150"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="opacity-0 translate-y-1"
                  >
                    <Popover.Panel className="absolute z-10 w-screen mt-3 -ml-8 transform max-w-max sm:px-0 lg:ml-0 lg:left-1/2 lg:-translate-x-1/2">
                      <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                        <div className="relative grid gap-6 px-5 py-6 bg-white ">
                          {data.belts.nodes.map((item) => (
                            <Link
                              key={`nav-${item.name}`}
                              to={`/class/${item.slug.current}s`}
                              className="flex items-start p-3 -m-3 rounded-lg hover:bg-gray-50"
                            >
                              <div className="ml-4">
                                <p className="text-base font-medium text-gray-900">
                                  {item.name}
                                  s
                                </p>
                              </div>
                            </Link>
                          ))}
                        </div>

                      </div>
                    </Popover.Panel>
                  </Transition>
                </>
              )}
            </Popover>
          </Popover.Group>

          <Popover.Group as="nav" className="hidden px-5 md:flex">
            <Popover className="relative">
              {({ open }) => (
                <>
                  <Popover.Button
                    className={classNames(
                      open ? 'text-gray-900' : 'text-gray-500',
                      'group rounded-md inline-flex items-center text-sm lg:text-base font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-4 focus:ring-gray-200',
                    )}
                  >
                    <p className="lg:flex">
                      <span className="hidden lg:flex lg:pr-1">Our</span>
                      Schools
                    </p>
                    <ChevronDownIcon
                      className={classNames(
                        open ? 'text-gray-600' : 'text-gray-400',
                        'ml-1 lg:ml-2 h-5 w-5 group-hover:text-gray-500',
                      )}
                      aria-hidden="true"
                    />
                  </Popover.Button>

                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-200"
                    enterFrom="opacity-0 translate-y-1"
                    enterTo="opacity-100 translate-y-0"
                    leave="transition ease-in duration-150"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="opacity-0 translate-y-1"
                  >
                    <Popover.Panel className="absolute z-10 w-screen px-2 mt-3 transform -translate-x-1/2 max-w-max left-1/2 sm:px-0">
                      <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                        <div className="relative grid gap-6 px-5 py-6 bg-white sm:gap-8 sm:p-8">
                          {data.schools.nodes.map((item) => (
                            <Link
                              key={`nav-${item.name}`}
                              to={`/school/${item.slug.current}`}
                              className="flex items-start p-3 -m-3 rounded-lg hover:bg-gray-50"
                            >
                              <div className="ml-4">
                                <p className="text-base font-medium text-gray-900">{item.name}</p>
                                <p className="mt-1 text-sm text-gray-500">{item.address.street}</p>
                                <p className="mt-1 text-sm text-gray-500">
                                  {item.address.city}
                                  {' '}
                                  {item.address.state}
                                  ,
                                  {' '}
                                  {item.address.zip}
                                </p>
                                <p className="mt-1 text-sm text-gray-500">
                                  {item.phone}
                                </p>
                              </div>
                            </Link>
                          ))}
                        </div>
                      </div>
                    </Popover.Panel>
                  </Transition>
                </>
              )}
            </Popover>
          </Popover.Group>

          <Popover.Group as="nav" className="hidden px-5 md:flex">
            <Popover className="relative">
              {({ open }) => (
                <>
                  <Popover.Button
                    className={classNames(
                      open ? 'text-gray-900' : 'text-gray-500',
                      'group rounded-md inline-flex items-center text-sm lg:text-base font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-4 focus:ring-gray-200',
                    )}
                  >
                    <p className="lg:flex">
                      <span className="hidden lg:flex lg:pr-1">Special</span>
                      {' '}
                      Events
                    </p>
                    <ChevronDownIcon
                      className={classNames(
                        open ? 'text-gray-600' : 'text-gray-400',
                        'ml-1 lg:ml-2 h-5 w-5 group-hover:text-gray-500',
                      )}
                      aria-hidden="true"
                    />
                  </Popover.Button>

                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-200"
                    enterFrom="opacity-0 translate-y-1"
                    enterTo="opacity-100 translate-y-0"
                    leave="transition ease-in duration-150"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="opacity-0 translate-y-1"
                  >
                    <Popover.Panel className="absolute z-10 w-screen px-2 mt-3 -ml-24 transform max-w-max sm:px-0 lg:ml-0 lg:left-1/2 lg:-translate-x-1/2">
                      <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                        <div className="relative grid gap-6 px-5 py-6 bg-white sm:gap-8 sm:p-8">
                          {specialEvents.map((item) => (
                            <Link
                              key={`nav-${item.name}`}
                              to={item.href}
                              className="flex items-start p-3 -m-3 rounded-lg hover:bg-gray-50"
                            >
                              <div className="ml-4">
                                <p className="text-base font-medium text-gray-900">{item.name}</p>
                              </div>
                            </Link>
                          ))}
                        </div>

                      </div>
                    </Popover.Panel>
                  </Transition>
                </>
              )}
            </Popover>
          </Popover.Group>
          <Link to="/about-us/" className="hidden pl-5 text-sm font-medium text-gray-500 md:flex lg:text-base hover:text-gray-900">
            About
            {' '}
            <span className="hidden lg:flex lg:pl-1">Us</span>
          </Link>
        </div>

        <Transition
          as={Fragment}
          enter="duration-200 ease-out"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="duration-100 ease-in"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <Popover.Panel focus className="absolute inset-x-0 top-0 p-2 transition origin-top-right transform md:hidden">
            <div className="bg-white divide-y-2 rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 divide-gray-50">
              <div className="px-5 pt-5 pb-6">
                <div className="flex items-center justify-between">
                  <GatsbyImage image={data.logo.childImageSharp.gatsbyImageData} alt="Yuan Yen Do Self Defense Logo" imgClassName="h-10" />

                  <div className="-mr-2">
                    <Popover.Button className="inline-flex items-center justify-center p-2 text-gray-400 bg-white rounded-md hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-gray-200">
                      <span className="sr-only">Close menu</span>
                      <XIcon className="w-6 h-6" aria-hidden="true" />
                    </Popover.Button>
                  </div>
                </div>
                <div className="mt-6">
                  <nav className="grid gap-y-8">
                    <Link
                      to="/"
                      className="flex items-center p-3 -m-3 rounded-md hover:bg-gray-50"
                    >
                      <span className="ml-3 text-base font-medium text-gray-900">Home</span>
                    </Link>
                  </nav>
                </div>
                <div className="mt-6">
                  <nav className="grid gap-y-8">
                    <Link
                      to="/classes"
                      className="flex items-center p-3 -m-3 rounded-md hover:bg-gray-50"
                    >
                      <span className="ml-3 text-base font-medium text-gray-900">Class Schedules</span>
                    </Link>
                  </nav>
                </div>
                <div className="mt-6">
                  <nav className="grid gap-y-8">
                    <Link
                      to="/our-schools"
                      className="flex items-center p-3 -m-3 rounded-md hover:bg-gray-50"
                    >
                      <span className="ml-3 text-base font-medium text-gray-900">Our Schools</span>
                    </Link>
                  </nav>
                </div>
                <div className="mt-6">
                  <nav className="grid gap-y-8">
                    <Link
                      to="/events"
                      className="flex items-center p-3 -m-3 rounded-md hover:bg-gray-50"
                    >
                      <span className="ml-3 text-base font-medium text-gray-900">Special Events</span>
                    </Link>
                  </nav>
                </div>
                <div className="mt-6">
                  <nav className="grid gap-y-8">
                    <Link
                      to="/about-us/"
                      className="flex items-center p-3 -m-3 rounded-md hover:bg-gray-50"
                    >
                      <span className="ml-3 text-base font-medium text-gray-900">About Us</span>
                    </Link>
                  </nav>
                </div>
              </div>
            </div>
          </Popover.Panel>
        </Transition>
      </Popover>
    </div>
  );
}
