import React from 'react';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import { PhoneIcon } from '@heroicons/react/solid';
import PropTypes from 'prop-types';

export default function CTA({ path }) {
  return (
    <div className="bg-white bg-cover shadow-inner bg-wavy fill-slate-400">
      <div className="container px-4 py-16 mx-auto max-w-7xl sm:px-6 lg:py-24 lg:px-8 lg:flex lg:items-center lg:justify-between">
        <h2 className="text-3xl font-extrabold tracking-tight text-center text-gray-900 sm:text-4xl lg:text-left">
          <span className="block">Ready to get started?</span>
          <span className="block text-blue-900">Contact us to sign up today.</span>
        </h2>
        <div className="flex justify-center mt-8 lg:mt-0 lg:flex-shrink-0">
          <div className="inline-flex rounded-md shadow">
            <AnchorLink
              to={`${path}#contact-us`}
              className="inline-flex items-center justify-center px-5 py-3 text-base font-medium text-white border border-transparent rounded-md bg-secondary hover:opacity-90"
            >
              <PhoneIcon className="w-6 h-6 pr-2" />
              Contact Us
            </AnchorLink>
          </div>
        </div>
      </div>
    </div>
  );
}

CTA.propTypes = {
  path: PropTypes.string.isRequired,
};
