import React from 'react';
import PropTypes from 'prop-types';
import Header from './Header';
import '../styles/global.css';
import Footer from './Footer';
import CTA from './CTA';
import CookieConsent from './ConsentBanner';

export default function Layout({ path, children }) {
  return (
    <div className="relative bg-background-light">
      <Header />
      <div className="mt-20">
        {children}
        <CTA path={path} />
      </div>
      <Footer />
      <CookieConsent />
    </div>

  );
}

Layout.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  children: PropTypes.any.isRequired,
  path: PropTypes.string.isRequired,
};
