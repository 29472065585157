exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-jsx": () => import("./../../../src/pages/about-us.jsx" /* webpackChunkName: "component---src-pages-about-us-jsx" */),
  "component---src-pages-classes-jsx": () => import("./../../../src/pages/classes.jsx" /* webpackChunkName: "component---src-pages-classes-jsx" */),
  "component---src-pages-events-birthday-parties-jsx": () => import("./../../../src/pages/events/birthday-parties.jsx" /* webpackChunkName: "component---src-pages-events-birthday-parties-jsx" */),
  "component---src-pages-events-jsx": () => import("./../../../src/pages/events.jsx" /* webpackChunkName: "component---src-pages-events-jsx" */),
  "component---src-pages-events-summer-camps-jsx": () => import("./../../../src/pages/events/summer-camps.jsx" /* webpackChunkName: "component---src-pages-events-summer-camps-jsx" */),
  "component---src-pages-events-tournaments-jsx": () => import("./../../../src/pages/events/tournaments.jsx" /* webpackChunkName: "component---src-pages-events-tournaments-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-our-schools-jsx": () => import("./../../../src/pages/our-schools.jsx" /* webpackChunkName: "component---src-pages-our-schools-jsx" */),
  "component---src-pages-privacy-policy-jsx": () => import("./../../../src/pages/privacy-policy.jsx" /* webpackChunkName: "component---src-pages-privacy-policy-jsx" */),
  "component---src-templates-belt-jsx": () => import("./../../../src/templates/belt.jsx" /* webpackChunkName: "component---src-templates-belt-jsx" */),
  "component---src-templates-school-jsx": () => import("./../../../src/templates/school.jsx" /* webpackChunkName: "component---src-templates-school-jsx" */)
}

